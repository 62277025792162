import React from 'react';
import Page from '../components/Page';
import { Card, CardBody, Col, Row, Button, Collapse, CardHeader,
    Form, FormGroup, Input, Label  } from 'reactstrap';
import { InnerLoaderComponent } from "../Loader";
import Api from "../Api/ApiUtils";
import { MdHome, MdInfo, MdError, MdFormatAlignJustify } from 'react-icons/md';
import { AiFillEyeInvisible } from 'react-icons/ai';
import CKEditor from 'ckeditor4-react';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE, } from '../utils/constants';
import { Link } from "react-router-dom";

class NewUserPanel extends React.Component {
    constructor() {
        super();
        this.state = {
            loader: false
        };
    }

    showPassword(field_id) {
        var x = document.getElementById(field_id);
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
    }

    createUser(){

        let firstName = document.getElementById("first_name").value;
        let lastName = document.getElementById("last_name").value;
        let userName = document.getElementById("user_name").value;
        let mobileNumber = document.getElementById("mobile_number").value;
        let email = document.getElementById("email").value;
        let password = document.getElementById("password").value;
        let confirmPassword = document.getElementById("confirm_password").value;

        let createBusiness = document.getElementById("use_business").checked;
        
        if(password.length > 0 && password == confirmPassword){
            
            if(window.confirm("Sei sicuro di voler procedere?")){

                const datetime = new Date().toISOString();

                let payload = {
                    "first_name": firstName,
                    "last_name": lastName,
                    "user_name": userName,
                    "mobile_number": mobileNumber,
                    "email": email,
                    "password": password,
                    "confirm_password": confirmPassword,
                    "accepted_terms_and_conditions": datetime,
                    "privacy_policy_version": 1,
                    "login_type": "Normal",
                    "ip_address": null
                };
                let _this = this;

                Api.registerUser(payload)
                    .then((res) => {
                        if (res) {
                            let result = res.data;
                            let user_id = result.id;

                            if(createBusiness){
                                let __this = _this;
                                let ppayload = {"id": user_id}
                                Api.addFreePlan(ppayload);
                                alert("Business user created successfully.");
                            }
                            else{                                
                                alert("User created successfully.");
                            }
                            //alert(JSON.stringify(res.data));
                        }
                        else {
                            alert("Something went wrong.");
                        }
                    })
                    .catch(function (err) {
                        if (err) {
                            _this.notificationSystem.addNotification({
                                title: <MdError />,
                                message: err.message,
                                level: 'error',
                            });
                        }
                        else {
                            _this.notificationSystem.addNotification({
                                title: <MdError />,
                                message: "Error Occurred",
                                level: 'error',
                            });
                        }
                        _this.setState({ loader: false });
                    });
            }
            
        }
    }


    componentDidMount() {
        //this.setState({ loader: true });
    }

    render() {
        return (
            <div>
                <Page>
                    <div className="d-flex align-items-end mb-5">
                        <h3 className="mb-0">Create New User</h3>
                        <div className="row align-items-center ml-3">
                            <div className="col-md-12 mb-0">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <MdHome />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/new-user">Create New User</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {this.state.loader ?
                        <InnerLoaderComponent />
                        : null
                    }
                    <Row className={"" + (this.state.loader === true ? "opacity" : "")}>
                        <Col>
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="first_name">Nome</Label>
                                <Input type="text" className="mr-0" placeholder="Nome" id="first_name" name="first_name"/>
                                
                                <Label for="last_name">Cognome</Label>
                                <Input type="text" className="mr-0" placeholder="Cognome" id="last_name" name="last_name"/>
                                
                                <Label for="user_name">Username</Label>
                                <Input type="text" className="mr-0" placeholder="Username" id="user_name" name="user_name"/>
                                
                                <Label for="email">Email</Label>
                                <Input type="text" className="mr-0" placeholder="Email" id="email" name="email"/>
                                
                                <Label for="password">Password</Label>
                                <AiFillEyeInvisible onClick={() => this.showPassword("password")}/>
                                <Input type="password" className="mr-0" placeholder="Password" id="password" name="password"/>
                                
                                <Label for="confirm_password">Conferma password</Label>
                                <AiFillEyeInvisible onClick={() => this.showPassword("confirm_password")}/>
                                <Input type="password" className="mr-0" placeholder="Conferma password" id="confirm_password" name="confirm_password"/>                                
                                
                                <Label for="mobile_number">Telefono</Label>
                                <Input type="text" className="mr-0" placeholder="Telefono" id="mobile_number" name="mobile_number"/>

                                <Input id="use_business" type="checkbox" style={{position: "relative", marginLeft: "3px"}} value="yes" className="mr-3" name="use_business" />
                                <Label for="use_business">Utente business</Label>

                                <Button
                                size="lg"
                                className="mr-2 bg-gradient-theme-left border-0"
                                block
                                onClick={/*this.handleSubmit*/() => this.createUser()}>
                                    Invia
                                </Button>
                            </FormGroup>
                                                  
                            
                        </Form>
                        </Col>
                    </Row>
                </Page>
                <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem =>
                        (this.notificationSystem = notificationSystem)
                    }
                    style={NOTIFICATION_SYSTEM_STYLE}
                />
            </div>
        );
    }
}
export default NewUserPanel;