// import { STATE_LOGIN, } from './components/AuthForm';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
// import AuthPage from './pages/AuthPage';
import React, { useRef } from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import axios from "axios";
import Login from './pages/Login';
import AboutUs from './contentPages/aboutUs';
import PrivacyPolicy from './contentPages/privacyPolicy';
import TermsCondition from './contentPages/termsCondition';
import FAQ from './contentPages/faq';
import NewUserPanel from './pages/NewUserPanel';

const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));
const Profile = React.lazy(() => import('./pages/Profile'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const UserManagement = React.lazy(() => import('./pages/UserManagement'));
const UserDetail = React.lazy(() => import('./pages/UserDetail'));
const ProfessionalUserManagement = React.lazy(() => import('./pages/ProfessionalUserManagement'));
const EventManagement = React.lazy(() => import('./pages/EventManagement'));
const EventDetail = React.lazy(() => import('./pages/EventDetail'));
const EmailTemplate = React.lazy(() => import('./pages/EmailTemplate'));
const ProfessionalUserDetail = React.lazy(() => import('./pages/ProfessionalUserDetails'));
const AppContent = React.lazy(() => import('./pages/AppContent'));
const AdsManagement = React.lazy(() => import('./pages/AdsManagement'));
const AdsEdit = React.lazy(() => import('./pages/AdsEdit'));
const Reports = React.lazy(() => import('./pages/Reports'));
const ContentHistory = React.lazy(() => import('./pages/ContentHistory'));
const NotificationsPanel = React.lazy(() => import('./pages/NotificationsPanel'));
const FormsPanel = React.lazy(() => import('./pages/FormsPanel'));
const EmailsPanel = React.lazy(() => import('./pages/EmailsPanel'));
const KPIPanel = React.lazy(() => import('./pages/KPIPanel'));

const TagManagement = React.lazy(() => import('./pages/TagManagement'));
const TagDetail = React.lazy(() => import('./pages/TagDetail'));

let axiosInterceptor = null;
const AuthRouter = ({ component: Component, ...rest }) => {
  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.response.eject(axiosInterceptor);
  }
  axiosInterceptor = axios.interceptors.response.use(function (response) {
    return response;
  }
    , function (error) {
      console.log("ERROR", error.response)
      if (error.response.status === 401) {
        localStorage.removeItem('popup_token');
        window.location.reload();
      }
    });
  return (
    <div>
      <Route {...rest} render={props => (
        (localStorage.getItem('popup_token') !== null) ? <Component {...props} /> :
          (
            <Redirect to={{
              pathname: "/login"
            }} />
          )
      )} />
    </div>
  )
};


function PrivateRoute({ children, ...rest }) {
  return (
    <Route exact
      {...rest}
      render={({ location, props }) =>
        localStorage.getItem('popup_token') != null ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}


class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <LayoutRoute
            path="/login" layout={EmptyLayout} component={props => (
              <Login
                {...props}
                authState='LOGIN'
              />
            )}
          />
          <LayoutRoute
            path="/about-us" layout={EmptyLayout} component={props => (
              <AboutUs
                {...props}
              />
            )}
          />
          <LayoutRoute
            path="/privacy-policy" layout={EmptyLayout} component={props => (
              <PrivacyPolicy
                {...props}
              />
            )}
          />
          <LayoutRoute
            path="/faq" layout={EmptyLayout} component={props => (
              <FAQ
                {...props}
              />
            )}
          />
          <LayoutRoute
            path="/terms-condition" layout={EmptyLayout} component={props => (
              <TermsCondition
                {...props}
              />
            )}
          />
          {/* <LayoutRoute
            exact
            path="/login1"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_LOGIN} />
            )}
          /> */}
          {/* <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          /> */}


          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <PrivateRoute path="/">
                <DashboardPage />
              </PrivateRoute>
              <PrivateRoute path="/new-user" >
                <NewUserPanel />
              </PrivateRoute>
              <PrivateRoute path="/users" >
                <Route exact path="/users" component={UserManagement} />
              </PrivateRoute>
              <PrivateRoute path="/users/:id" >
                <Route exact path="/users/:id" component={UserDetail} />
              </PrivateRoute>
              <PrivateRoute path="/professional-user">
                <Route exact path="/professional-user" component={ProfessionalUserManagement} />
              </PrivateRoute>
              <PrivateRoute path="/professional-user/:id" >
                <Route exact path="/professional-user/:id" component={ProfessionalUserDetail} />
              </PrivateRoute>
              <PrivateRoute path="/event">                
                <Route exact path="/event" component={EventManagement} />
              </PrivateRoute>
              <PrivateRoute path="/tags">                
                <Route exact path="/tags" component={TagManagement} />
              </PrivateRoute>
              <PrivateRoute path="/tag/:id">                
                <Route exact path="/tag/:id" component={TagDetail} />
              </PrivateRoute>
              <PrivateRoute path="/report">                
                <Route exact path="/report" component={Reports} />
              </PrivateRoute>
              <PrivateRoute path="/change-password">
                <ChangePassword />
              </PrivateRoute>
              <PrivateRoute path="/template">
                <EmailTemplate />
              </PrivateRoute>
              <PrivateRoute path="/profile">
                <Profile />
              </PrivateRoute>
              <PrivateRoute path="/ads" >
                <AdsManagement />
              </PrivateRoute>
              <PrivateRoute path="/content-history" >
                <ContentHistory />
              </PrivateRoute>
              <PrivateRoute path="/ads/:id" >
                <Route exact path="/ads/:id" component={AdsEdit} />
              </PrivateRoute>
              <PrivateRoute path="/event/:id" >
                <Route exact path="/event/:id" component={EventDetail} />
              </PrivateRoute>
              <PrivateRoute path="/app-content">
                <AppContent />
              </PrivateRoute>
              <PrivateRoute path="/notifications">
                <NotificationsPanel />
              </PrivateRoute>
              <PrivateRoute path="/forms">
                <FormsPanel />
              </PrivateRoute>
              <PrivateRoute path="/emails">
                <EmailsPanel />
              </PrivateRoute>
              <PrivateRoute path="/kpi">
                <KPIPanel />
              </PrivateRoute>
            </React.Suspense>
          </MainLayout>
          <Redirect to="/login" />
        </Switch>
        {/* <ToastContainer autoClose={5000} /> */}
      </Router>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
