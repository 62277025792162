import axios from "axios";
import LocalStorage from "./LocalStorage";
//const API_URL = "https://api-preprod.popup.lol/";
//const CP_API_URL = "https://cp.api-preprod.popup.lol/";

const API_URL = process.env.REACT_APP_BASE_API;
const CP_API_URL = process.env.REACT_APP_BASE_CP_API;
// const API_URL = "http://fb2ce6a98fad.ngrok.io/";

async function downloadImage(responseData, filename = 'download.png') {
    try {
  
      // Crea un URL per il blob
      const url = window.URL.createObjectURL(new Blob([responseData], { type: 'image/png' }));
  
      // Crea un elemento <a> per scaricare il blob come file
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); // Nome del file per il download
      document.body.appendChild(link);
      link.click();
  
      // Pulizia
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Errore durante il download dell\'immagine:', error);
    }
  }

const ApiUtils = {

    //Admin login
    login: function (payload) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.post(API_URL + "admin/login", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Admin forgot password
    forgotPassword: function (payload) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.post(API_URL + "admin/forgotPassword", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data) {
                    throw error.response.data
                }
            });
    },

    //Admin Change password
    changePassword: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/changePassword", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data) {
                    throw error.response.data
                }
            });
    },

    //Dashboard
    getDashboard: async function () {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.get(API_URL + "admin/dashboard", {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Get Email Template
    getEmailTemplate: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "mailTemplate/getMailTemplate", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Edit Email Template
    editMailTemplate: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "mailTemplate/editMailTemplate", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },
    
    //Events listing
    allEvents: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/allEvents", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Deactivate Event
    deactivateEvent: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/deactivateEvent", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Delete Event
    deleteEvent: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api-key": TOKEN
        };
        return axios.post(API_URL + "event/deleteEvent", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Events listing
    fetchEvent: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/fetchEvent", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //User listing
    userList: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/allUsers", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Deactivate User
    deactivateUser: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/deactivateUser", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Fetch User
    fetchUser: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/fetchUser", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Fetch Professional User
    fetchProfessionalUser: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "user/fetchProfessionalUser", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Edit User
    editUser: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/update", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Delete User
    deleteUser: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/deleteUser", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //verify User
    verifyUser: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/verifyUser", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Get app content
    getAppContent: async function () {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            // "api_key": TOKEN
        };
        return axios.get(API_URL + "appcontent/all", {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Get app content
    getAppContentHistory: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "appcontent/getAppContentHistory", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data) {
                    throw error.response.data
                }
            });
    },

    //Set app content
    setAppContent: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "appcontent/addUpdate", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data) {
                    throw error.response.data
                }
            });
    },

    //Professional User listing
    professionalUserList: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "admin/allProfessionalUsers", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //get All Ads
    getAllAds: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "ads/getAllAds", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Add Ads
    addAds: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "ads/addAds", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Edit Ads
    editAds: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "ads/editAds", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //Delete Ads
    deleteAd: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "ads/deleteAd", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },
    //Fetch Ads
    fetchAd: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "ads/fetchAd", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //get all reports
    getAllReports: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "report/getAllReports", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    //report status change
    changeReportStatus: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "report/changeStatus", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // update host
    updateHost: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "popup/updateHost/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // NEW
    generateTag: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "tag/generate", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // update alias
    updateAlias: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "popup/editAlias/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // generate QR code
    generateQRCode: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "popup/generate_qr_code/", payload, {
            headers: headers,
            responseType: 'blob'
        })
            .then(response => {                
                if (response && response.data) {
                    const url = downloadImage(response.data, "qr_code_" + payload.popup_id + ".png");
                    //Buffer.from(response.data, 'binary').toString('base64')
                    //return response.data
                }
            })
            .catch(error => {
                throw {data:
                    "There is an issue with the API request. Maybe you have performed too many API calls. Please wait a few minutes and try again."}
            });
    },

    // add new collaborator
    addCollaborator: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "popup/addCollaborator/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // remove collaborator
    removeCollaborator: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "popup/removeCollaborator/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // send notification
    triggerCustomNotification: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "event/triggerCustomNotification", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    triggerEmail: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "send_email_to_users/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },



    // new API calls @dsciarroni

    getForms: async function () {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "popup/form/getAllForms", {}, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // NEW
    getTags: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "tag/list", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // NEW
    exportTags: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "tag/export", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    // TBD
    editMultipleTags: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.put(CP_API_URL + "tag/multiple/update", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    deleteTag: async function (tag_id) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "tag/delete/" + tag_id, {}, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    getTag: async function (tag_id) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "tag/get/" + tag_id, {}, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    editTag: async function (tag_id, payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.put(CP_API_URL + "tag/update/" + tag_id, payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    transferTag: async function (tag_id, payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.put(CP_API_URL + "tag/transfer/" + tag_id, payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    scanTag: async function (tag_uuid, payload) {
        let TOKEN = "#asjrkljn@!Aepvfo20@";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "tag/scan/" + tag_uuid, payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    getSheets: async function () {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "get_spreadsheets/", {}, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    getSubscriptions: async function () {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "get_subscriptions/", {}, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    editUserSubscription: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "edit_user_subscription/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    createSheet: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "prepare_spreadsheet_for_existing_popup/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    enableScript: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "enable_spreadsheet/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    disableScript: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "disable_spreadsheet/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },


    //report status change
    getLocalizedUsers: async function (payload) {
        let TOKEN = "@Aiss#asjrep20@2v.neqa#r";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "get_localized_users/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },
    getNonLocalizedUsers: async function (payload) {
        let TOKEN = "#asjrkljn@!Aepvfo20@";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "get_non_localized_users/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    registerUser: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "user/register", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    resetPassword: async function (payload) {
        let TOKEN;
        await LocalStorage.getItem("popup_token")
            .then(function (result) {
                TOKEN = result;
            });
        const headers = {
            'Content-Type': 'application/json',
            "api_key": TOKEN
        };
        return axios.post(API_URL + "user/resetPassword", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    addFreePlan: async function (payload) {
        let TOKEN = "akljnv13bvi2vfo0b0bw";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "add_free_plan/", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    resetMenu: async function (payload) {
        let TOKEN = "akljnv13bvi2vfo0b0bw";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "popup/menu/reset", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    },

    restoreMenu: async function (payload) {
        let TOKEN = "akljnv13bvi2vfo0b0bw";
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + TOKEN
        };
        return axios.post(CP_API_URL + "popup/menu/restore", payload, {
            headers: headers
        })
            .then(response => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            });
    }



}
export default ApiUtils;