import logo from '../assets/img/popupNameLogo.png';
import React from 'react';
import SimpleReactValidator from "simple-react-validator";
import { Row, Col, Card, Form, FormGroup, Input, Label, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { MdInfo, MdError, } from 'react-icons/md';
import {OuterLoaderComponent} from "../Loader";
import Api from "../Api/ApiUtils";
import LocalStorage from "../Api/LocalStorage";
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';

class Login extends React.Component {

    constructor() {
        super();
        this.state = {
            loader: false,
            email: "",
            password: "",
            forgotEmail: "",
            forgotModal: false,
        }
        this.loginValidator = new SimpleReactValidator();
        this.forgotValidator = new SimpleReactValidator();
    }

    componentDidMount() {
    }

    login() {
        let _this = this;
        this.setState({ loader: true });
        if (this.loginValidator.allValid()) {
            let payload = {
                email: this.state.email,
                password: this.state.password,
            }
            Api.login(payload)
                .then((res) => {
                    if (res) {
                        if (res.data) {
                            LocalStorage.setItem("user", JSON.stringify(res.data)).then(fulfilled => {
                                LocalStorage.setItem("popup_token", JSON.stringify(res.data.token)).then(success => {
                                    this.notificationSystem.addNotification({
                                        title: <MdInfo />,
                                        message: "Login Successful!!",
                                        level: 'success',
                                    });
                                    this.props.history.push("/");
                                });
                            });                            
                        }
                    }
                    this.setState({ loader: false });
                })
                .catch(function (err) {
                    if (err) {
                        _this.notificationSystem.addNotification({
                            title: <MdError />,
                            message: "Login Unsuccessful!!",
                            level: 'error',
                        });
                    }
                    else {
                        _this.notificationSystem.addNotification({
                            title: <MdError />,
                            message: "Error Occurred",
                            level: 'error',
                        });
                    }
                    _this.setState({ loader: false });
                });
        }
        else {
            this.loginValidator.showMessages();
            this.forceUpdate();
            this.setState({ loader: false });
        }
    }

    forgotPassword() {
        let _this = this;
        this.setState({ loader: true });
        if (this.forgotValidator.allValid()) {
            let payload = {
                email: this.state.forgotEmail
            };
            Api.forgotPassword(payload)
                .then((res) => {
                    if (res) {
                        this.notificationSystem.addNotification({
                            title: <MdInfo />,
                            message: "Password sent successfully to your email!!",
                            level: 'success',
                        });
                        this.setState({ forgotModal: false, loader: false });
                    }
                })
                .catch(function (err) {
                    if (err) {
                        _this.notificationSystem.addNotification({
                            title: <MdError />,
                            message: err.message,
                            level: 'error',
                        });
                    }
                    else {
                        _this.notificationSystem.addNotification({
                            title: <MdError />,
                            message: "Error Occurred",
                            level: 'error',
                        });
                    }
                    _this.setState({ loader: false });
                });
        }
        else {
            this.forgotValidator.showMessages();
            this.forceUpdate();
            this.setState({ loader: false });
        }

    }

    onKeyPress(e) {
        if (e.which === 13) {
            this.login();
        }
    }

    setStateFromInput(event) {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    render() {
        return (
            <div>
                {this.state.loader ?
                    <OuterLoaderComponent />
                    : null
                }
                <Row className={"" + (this.state.loader === true ? "opacity" : "")} style={{ height: '100vh', justifyContent: 'center', alignItems: 'center', }}>
                    <Col md={6} lg={4}>
                        <Card body>
                            <Form >
                                <div className="text-center pb-4">
                                    <img
                                        src={logo}
                                        className="rounded"
                                        style={{ width: 160, height: 60, cursor: 'pointer' }}
                                        alt="logo"
                                    />
                                </div>
                                <FormGroup>
                                    <Label >Email:</Label>
                                    <Input name="email" value={this.state.email} onChange={this.setStateFromInput.bind(this)} onKeyPress={this.onKeyPress.bind(this)} />
                                    {this.loginValidator.message('Email', this.state.email, 'required')}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Password:</Label>
                                    <Input name="password" type="password" value={this.state.password} onChange={this.setStateFromInput.bind(this)} onKeyPress={this.onKeyPress.bind(this)} />
                                    {this.loginValidator.message('Password', this.state.password, 'required')}
                                </FormGroup>
                                {/* <div className="row">
                                    <div className="col-md-7"></div>                                    
                                </div> */}
                                <div className="row mt-4">
                                    <Button color="primary" className="col-md-4 ml-3 border-0 btn-block float-right" onClick={this.login.bind(this)} >Login</Button>
                                    <div className="col-7 pr-0 ml-2">
                                        <span className="float-right mt-2 pointer" style={{ fontSize: "14px" }} onClick={() => this.setState({ forgotModal: !this.state.forgotModal })}><strong>FORGOT YOUR PASSWORD?</strong></span>
                                    </div>
                                </div>
                            </Form>
                        </Card>
                    </Col>
                    <Modal isOpen={this.state.forgotModal} toggle={() => this.setState({ forgotModal: !this.state.forgotModal })}>
                        <ModalHeader toggle={() => this.setState({ forgotModal: !this.state.forgotModal })}>Send Forgot Password Link</ModalHeader>
                        <ModalBody>
                            <div className="input-group">
                                <input type="text" className="form-control mt-3" placeholder="Email" name='forgotEmail' value={this.state.forgotEmail} onChange={this.setStateFromInput.bind(this)} />
                            </div>
                            {this.forgotValidator.message('Email', this.state.forgotEmail, 'required|email')}
                            <div className="mt-4">
                                <Button color="success" className="theme-orange text-white mr-2 float-left" onClick={this.forgotPassword.bind(this)}>Send</Button>
                                <button className="float-left btn color-grey" onClick={() => this.setState({ forgotModal: !this.state.forgotModal })}>Cancel</button>
                            </div>
                        </ModalBody>
                    </Modal>
                </Row>
                <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem =>
                        (this.notificationSystem = notificationSystem)
                    }
                    style={NOTIFICATION_SYSTEM_STYLE}
                />
            </div>
        );
    }
}
Login.defaultProps = {
    authState: 'LOGIN',
}
export default Login;
