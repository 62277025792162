import LocalStorage from "../../Api/LocalStorage";
import React from 'react';
import { MdClearAll,  } from 'react-icons/md';
import { Card, CardTitle, CardSubtitle, CardBody, Button, Nav, Navbar, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, } from 'reactstrap';
import bn from '../../utils/bemnames';
import { Link, withRouter } from 'react-router-dom';
import avatarImg from "../../assets/img/avatar.png"
const bem = bn.create('header');


class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
    };
  }

  componentDidMount() {
    LocalStorage.getItem("user").then(user => {
      this.setState({ user: user })
    })
  }

  signOut(e) {
    LocalStorage.removeItem("user").then(res => {
      LocalStorage.removeItem("popup_token").then(success => {
        e.preventDefault();
        this.props.history.push('/login');
      });
    });
  }

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    return (
      <Navbar light expand className={bem.b('bg-white mb-3')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar className={bem.e('nav-right')}>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <img className="rounded-circle" width="50" height="50" src={this.state.user && this.state.user.profile_picture ? avatarImg : avatarImg} alt="profile" />
            </DropdownToggle>
            <DropdownMenu right className="p-0">
              <DropdownItem header tag="div" className="p-0 text-center">
                <Card inverse className="border-light" color="primary">
                  <CardBody className="d-flex justify-content-center align-items-center flex-column" style={{ minWidth: 250 }}>
                    <img className="rounded-circle mb-2" width="50" height="50" src={this.state.user && this.state.user.profile_picture ? avatarImg : avatarImg} alt="profile" />
                    <CardTitle>{this.state.user && this.state.user.first_name ? this.state.user.first_name : ""}</CardTitle>
                    <CardSubtitle>{this.state.user && this.state.user.email ? this.state.user.email : ""}</CardSubtitle>
                  </CardBody>
                </Card>
              </DropdownItem>
              <DropdownItem tag={Link} to="/profile"><i className="fa fa-profile"></i>Profile</DropdownItem>
              <DropdownItem tag={Link} to="/change-password"><i className="fa fa-profile"></i>Change Password</DropdownItem>
              <DropdownItem onClick={e => this.signOut(e)}><i className="fa fa-lock"></i> Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    );
  }
}

export default withRouter(Header);
