import React from 'react';
import $ from 'jquery';
import Api from '../Api/ApiUtils';
import { Fragment } from 'react';
import { MdError } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';

class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: '',
        }
    }
    getData() {
        Api.getAppContent().then(async res => {
            if (res && res.data) {
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].content_type === "PrivacyPolicy") {
                        this.setState({ data: res.data[i].content }, () => {
                            $(".content").html(this.state.data);
                        });
                    }
                }
            }
        })
            .catch(err => {
                if (err) {
                    this.notificationSystem.addNotification({
                        title: <MdError />,
                        message: err.message,
                        level: 'error',
                    });
                }
                else {
                    this.notificationSystem.addNotification({
                        title: <MdError />,
                        message: "Error Occurred",
                        level: 'error',
                    });
                }
                this.setState({ loader: false });
            })
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div>
                <Fragment >
                    <div className="content app-content">{this.state.data}</div>
                </Fragment >
                <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem =>
                        (this.notificationSystem = notificationSystem)
                    }
                    style={NOTIFICATION_SYSTEM_STYLE}
                />
            </div>
        );
    }
}

export default PrivacyPolicy;
