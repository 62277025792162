import Loader from "react-loader-spinner";
import React from 'react';

export class OuterLoaderComponent extends React.Component {
   render() {
      return (
         <Loader type="Rings" color="#000B18" width={220} height={220} className="loader" />
      );
   }
}

export class InnerLoaderComponent extends React.Component {
   render() {
      return (
         <Loader type="Rings" color="#000B18" width={220} height={220} className="loader shiftLeft" />
      );
   }
}